<template>
  <div>
    <div class="banner" id="aboutus_banner1" ref="aboutus_banner1" :style="{backgroundImage:('url('+banner+')')}">
      <!-- <img :src="banner" /> -->
      <!-- https://cdqdsyfile.oss-accelerate.aliyuncs.com/upload/1-21042110154D48_1659938294966_1666772445153.jpg -->
      <span
        >{{ $t("indexnav.Milestones")
        }}<i
          :style="{
            display: this.$store.state.nowlanguage === 'en' ? 'none' : '',
          }"
          >Milestones</i
        ></span
      >
    </div>

    <section class="ab-item1">
      <!-- 页面跳转 -->
      <!-- <a href="/">{{ $t("milestone.Home") }}</a> >
      <a href="/aboutus/synopsis">{{ $t("milestone.About JinXin") }}</a> >
      <a>{{ $t("milestone.Milestones") }}</a> -->
      <bread-crumb />
    </section>
    <article>
      <!-- 好孕一站式医疗管理服务 -->
      <p>
        {{ $t("milestone.management") }}<br />
        {{ $t("milestone.leading") }}
      </p>
      <div>
        <dl class="lichengbei">
          <!-- 锦欣里程碑内容  year:年份 info:内容-->
          <dd
            v-for="(item, index) in dataList"
            :key="index"
            :class="((index + 1) / 2).toString().includes('.') ? 'lft' : 'rft'"
          >
            <img
              v-if="!((index + 1) / 2).toString().includes('.')"
              :src="item.imgUrl"
            />
            <div>
              <span>{{ filterLang(item, "Title") }}</span>
              <span class="sp1">
                {{ filterLangNoWordwrap(item, "Content") }}
              </span>
            </div>
            <img
              v-if="((index + 1) / 2).toString().includes('.')"
              :src="item.imgUrl"
            />
          </dd>
        </dl>
      </div>
    </article>
  </div>
</template>

<script>
import { LichengbeiDataList } from "@/api/Lichengbei";
import { filterLang, filterLangNoWordwrap } from "@/utils/LangChange";
import { PageType } from "@/utils/constant";
import { getAction } from "@/api/manage";

export default {
  methods: {
    filterLang,
    filterLangNoWordwrap,
  },
  data() {
    return {
      dataList: [],
      banner: "",
    };
  },
  created() {
    // 初始化banner
    let that = this;
    getAction("/jeecg-system/common/frontend/advertise/active_list", {
      typeCode: PageType.LICHENGBEI,
    }).then((res) => {
      console.log(res);
      if (
        res.result.records instanceof Array &&
        res.result.records.length > 0
      ) {
        if (res.result.records[0].isMultipatLanguge!=='0') {
          if (this.$store.state.nowlanguage == "zh")
            that.banner = res.result.records[0].sampleImg;
          if (this.$store.state.nowlanguage == "tr")
            that.banner = res.result.records[0].tranditionalImg;
          if (this.$store.state.nowlanguage == "en")
            that.banner = res.result.records[0].enImg
        }else{
        that.banner = res.result.records[0].sampleImg
        }
        // let aboutus_banner1=document.getElementById('aboutus_banner1')
        // aboutus_banner1.style.backgroundImage=`url(${this.banner})`
      }
    });

    LichengbeiDataList().then((res) => {
      console.log(res);
      this.dataList = res.result;
    });
  },
};
</script>

<style scoped lang='less'>
.ab-item1 {
  padding: 0 0 1.5em 0;
  border-bottom: 1px solid #d2d2d2;
  margin-bottom: 1.5em;
  font-size: 1.5rem;
  color: #595757;
}
article {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  overflow: hidden;
}

article p {
  color: #0061ae;
  font-size: 1.6rem;
  margin-bottom: 1.2em;
  line-height: 1.6em;
  padding: 0 1.5em 1.5em 1.5em;
}

article div {
  display: flex;
  justify-content: center;
}

article div > dl {
  margin-left: 30em;
  border-left: #165da3 3px solid;
}

article div > dl dd img {
  width: 6em;
  height: 6em;
  border-radius: 50%;
}

article div > dl dd.lft {
  display: flex;
  margin-left: -30.9em;
  padding-left: 1em;
  position: relative;
  align-items: center;
  justify-content: end;
  text-align: right;
}

article div > dl dd.rft {
  display: flex;
  padding-left: 1em;
  position: relative;
  align-items: center;
}

article div > dl dd div {
  line-height: 1.6;
  color: #221815;
  width: 22em;
}

article div > dl dd.lft div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 0.8em;
}

article div > dl dd.rft div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 0.8em;
}

article div > dl dd + dd {
  margin-top: 1em;
}

article div > dl dd span.sp1 {
  font-weight: normal;
  color: #221815;
  font-size: 1em;
}

article div > dl dd span {
  display: block;
  font-weight: bold;
  color: #2693d0;
  font-size: 1.2em;
}

article div > dl dd.lft:after {
  width: 1em;
  height: 1em;
  content: "";
  background-color: #c81a41;
  position: absolute;
  top: 2.75em;
  left: 30.3em;
  z-index: 2;
  display: block;
  border-radius: 100%;
}

article div > dl dd.rft:before {
  width: 1em;
  height: 1em;
  content: "";
  background-color: #c81a41;
  position: absolute;
  top: 2.75em;
  left: -0.57em;
  z-index: 2;
  display: block;
  border-radius: 100%;
}
@media screen and (max-width: 1000px) {
  article div > dl dd.lft img {
    left: 0.6em;
    position: absolute;
  }
  article div > dl {
    margin-left: 0;
  }
  article div > dl dd.lft {
    display: flex;
    margin-left: 0;
    padding-left: 1em;
    text-align: left;
  }
  article div > dl dd.lft div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 4.8em;
  }
  article div {
    padding: 0 1.5em 1.5em 1.5em;
    justify-content: flex-start;
  }
  article div > dl dd span {
    display: block;
    font-weight: bold;
    color: #2693d0;
    font-size: 1.2em;
  }
  article div > dl dd.rft div {
    margin-left: -1em;
  }
  article div > dl dd.lft:after {
    left: -0.65em;
  }
}
</style>
